<div class="footer-main-section">
  <div class="flex-container">
    <div class="flex-column">
      <h2>More in Woolworths Group...</h2>
      <ul class="footer-links-list">
        <li *ngFor="let i of moreInWooliesLinks">
          <a class="footer-link" [href]="i.link" target="_blank">{{
            i.label
          }}</a>
        </li>
      </ul>
    </div>
    <div class="flex-column">
      <h2>Related Sites</h2>
      <ul class="footer-links-list">
        <li *ngFor="let i of relatedSitesLinks">
          <a class="footer-link" [href]="i.link" target="_blank">{{
            i.label
          }}</a>
        </li>
      </ul>
    </div>
    <div class="flex-column">
      <h2>About this site</h2>
      <ul class="footer-links-list">
        <li *ngFor="let i of aboutLinks">
          <a class="footer-link" [href]="i.link" target="_blank">{{
            i.label
          }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="flex-container mobile-center">
    <div class="flex-column">
      © Woolworths Group 1997 - {{ currentYear }} All Rights Reserved ABN 88
      000 014 675
    </div>
    <div class="flex-column max-width-200">
      <img src="assets/logo.svg" width="150" />
    </div>
  </div>
</div>

<div class="flex-container mobile-center">
  <div class="flex-column max-width-100">
    <img src="assets/tb-care-deeply-artwork.png" />
  </div>
  <div class="flex-column">
    <p>
      We acknowledge the Traditional Owners and Custodians of Country throughout
      Australia. We pay our respects to all First Nations peoples and
      acknowledge Elders past and present.
    </p>
    <a
      href="https://www.woolworthsgroup.com.au/au/en/reconciliation/reconciliation-at-woolworths-group.html"
      target="_blank"
    >
      Read more about our commitment to reconciliation
    </a>
  </div>
</div>
