export const OTP_REGEX = '^[0-9]{6}$';

export const GENERIC_ERROR = 'Something went wrong! Please try again later.';

export const api_url = {
  LANDING_VALIDATION: '/wx/v1/loyalty/sdc/self-serve/employees/login',
  OTP_REGISTER: '/wx/v1/loyalty/sdc/self-serve/employees/one-time-passcode',
  OTP_VERIFY: '/wx/api-otp/verifytoken/sdcregister',
};
export const LIBRARY_LABELS = {
  otp: {
    OTP_HEADER: "We've sent the one time verification code to",
    OTP_EMAIL_PROMPT:
      '*Codes can take a few minutes to arrive. Email may be in another folder, such as Promotions or Spam.',
    OTP_MOBILE_PROMPT: '*Codes can take a few minutes to arrive.',
    OTP_OTHER_VERIFICATION: 'Send the code to',
    OTP_DINT_GET_CODE: "Didn't get your code?",
    OTP_SEND_AGAIN: 'Send me the code',
    OTP_RESENT: 'One time verification code has been sent',
    OTP_CODE: 'Enter your 6 digit code',
    OTP_VERIFY: 'Verify',
    OTP_CANCEL: 'Cancel',
    OTP_HELP:
      " Experiencing problems? We're here to help. Call 1300 10 1234, option 1. We're around Monday to Friday, 9am - 7pm; and Saturday & Sunday, 10am - 4pm (AEST)",
    OTP_INVALID_INPUT: 'Please enter 6 digit one time passcode',
  },
};
