<div class="card-container">
  <img *ngIf="imageUrl" [src]="imageUrl" alt="Card Image" />
  <div class="card-body card-flex-grow">
    <h2 class="card-heading">{{ heading }}</h2>
    <div [innerHTML]="description"></div>
  </div>

  <div class="card-footer">
    <a
      *ngIf="!loginToLearnMore && showSingleCTA && callToActionsTable"
      [href]="getURL(callToActionsTable && callToActionsTable[0]?.fields)"
      [attr.target]="
        callToActionsTable && callToActionsTable[0].fields.openInNewTab
          ? '_blank'
          : '_self'
      "
      [attr.rel]="
        callToActionsTable && callToActionsTable[0].fields.openInNewTab
          ? 'noopener noreferrer'
          : null
      "
      class="cta-button"
    >
      {{ callToActionsTable && callToActionsTable[0].fields.label }}

      <svg
        *ngIf="callToActionsTable && callToActionsTable[0].fields.openInNewTab"
        class="icon"
      >
        <use xlink:href="/assets/icons-sprite.svg#externallink"></use>
      </svg>

      <svg
        *ngIf="callToActionsTable && !callToActionsTable[0].fields.openInNewTab"
        class="icon"
      >
        <use xlink:href="/assets/icons-sprite.svg#arrow-right"></use>
      </svg>
    </a>

    <div *ngIf="loginToLearnMore">
      <div class="login-to-learn-more">Login to learn more</div>
    </div>
  </div>

  <ng-container
    *ngIf="!loginToLearnMore && !showSingleCTA && callToActionsTable"
  >
    <div class="multi-link" *ngFor="let cta of callToActionsTable">
      <p
        class="multi-link-paragraph"
        *ngIf="
          cta.fields.linkType === 'Woolworths Group team members' ||
          cta.fields.linkType === 'Endeavour Group team members'
        "
      >
        {{ cta.fields.linkType }}
      </p>
      <a
        *ngIf="cta.fields.linkType === 'Regular link'; else otherLink"
        [href]="getURL(cta?.fields)"
        class="cta-button"
        [attr.target]="cta.fields.openInNewTab ? '_blank' : '_self'"
        [attr.rel]="cta.fields.openInNewTab ? 'noopener noreferrer' : null"
        >{{ cta.fields.label }}

        <svg *ngIf="cta.fields.openInNewTab" class="icon">
          <use xlink:href="/assets/icons-sprite.svg#externallink"></use>
        </svg>

        <svg *ngIf="!cta.fields.openInNewTab" class="icon">
          <use xlink:href="/assets/icons-sprite.svg#arrow-right"></use>
        </svg>
      </a>
      <ng-template #otherLink>
        <a
          [href]="getURL(cta?.fields)"
          [attr.target]="cta.fields.openInNewTab ? '_blank' : '_self'"
          [attr.rel]="cta.fields.openInNewTab ? 'noopener noreferrer' : null"
        >
          {{ cta.fields.label }}
        </a>
      </ng-template>
    </div>
  </ng-container>

  <img
    *ngIf="footerImageUrl"
    [src]="footerImageUrl"
    alt="Footer Image"
    class="footer-image"
  />
</div>
