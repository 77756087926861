import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchResult } from '../../shared/interfaces/search-results';
import { SearchService } from '../../search.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {
  searchResults: SearchResult[] = [];

  constructor(private router: Router, private searchService: SearchService, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const searchTerm = params['term'];
      if (searchTerm) {
        this.searchService.performSearch(searchTerm).subscribe((results) => {
          this.searchResults = results;
        });
      }
    });
  }

  async onResultSelect(result: SearchResult): Promise<void> {
    await this.router.navigateByUrl(`${result.slug}`);
  }
}
