import { NgModule, inject } from '@angular/core';
import { ResolveFn, RouterModule, Routes } from '@angular/router';
import { map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { HomeComponent } from './home/home.component';
import { IAmHereModule } from './i-am-here/i-am-here.module';
import { ContentService } from './content.service';
import { MaintenanceGuard } from './shared/guards/maintenance.guard';
import { MaintenanceComponent } from './shared/components/maintenance/maintenance.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { HOME_CONTENT_ID, MAINTENANCE_CONTENT_ID } from './shared/constants';

export const contentByIdResolver: ResolveFn<any> = () => {
  const contentId = HOME_CONTENT_ID;
  const contentService = inject(ContentService);
  const titleService = inject(Title);

  return contentService.getContentById(contentId).pipe(
    map((content) => {
      const title = content?.fields?.title || '';
      titleService.setTitle(`Team Benefits | ${title}`);
      return content;
    })
  );
};

export const maintenanceContentResolver: ResolveFn<any> = () => {
  const contentId = MAINTENANCE_CONTENT_ID;
  const contentService = inject(ContentService);
  const titleService = inject(Title);

  return contentService.getContentById(contentId).pipe(
    map((content) => {
      titleService.setTitle('Maintenance Page');
      return content;
    })
  );
};

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    component: HomeComponent,
    resolve: { content: contentByIdResolver },
  },
  {
    path: 'i-am-here',
    loadChildren: () => IAmHereModule,
  },
  {
    path: 'valued-benefits',
    loadChildren: () => import('./valued-benefits/valued-benefits.module').then(
      (m) => m.ValuedBenefitsModule,
    ),
  },
  {
    path: 'everyday-rewards-plus',
    loadChildren: () => import('./everyday-rewards/everyday-rewards.module').then(
      (m) => m.EverydayRewardsModule,
    ),
  },
  {
    path: 'team-discount-selfserve',
    loadChildren: () => import('./self-serve/self-serve.module').then((m) => m.SelfServeModule),
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'activation',
    redirectTo: 'card-activation',
    pathMatch: 'full',
  },
  {
    path: 'card-activation',
    loadChildren: () => import('./card-activation/card-activation.module').then(
      (m) => m.CardActivationModule,
    ),
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'team-discount-selfserve/faqs',
    loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'search',
    component: SearchResultsComponent
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    resolve: { content: maintenanceContentResolver }
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
