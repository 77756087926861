import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRouteSnapshot } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { FooterComponent } from './components/footer/footer.component';
import { SearchComponent } from './components/search/search.component';
import { BannerComponent } from './components/banner/banner.component';
import { HomeComponent } from './home/home.component';
import { CardComponent } from './components/card/card.component';
import { CardActivationModule } from './card-activation/card-activation.module';

import { UrlRewriterService } from './url-rewriter.service';
import { CallToActionsComponent } from './components/call-to-actions/call-to-actions.component';
import { RichTextPipe } from './rich-text.pipe';
import { HeroBannerComponent } from './components/hero-banner/hero-banner.component';
import { ActivationHttpInterceptor } from './card-activation/interceptors/activation-http.interceptor';
import { SelfServeHttpInterceptor } from './self-serve/form/interceptors/selfserve-http.interceptor';
import { ContentInterceptor } from './interceptors/content.interceptor';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';
import { LoaderComponent } from './card-activation/components/loader/loader.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { MaintenanceComponent } from './shared/components/maintenance/maintenance.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { AuthorControlsComponent } from './components/author-controls/author-controls.component';
import { SharedModule } from './shared/shared.module';

/**
 * Function to initialize the application by rewriting URLs without the .html extension.
 * This function is used as an APP_INITIALIZER provider in the AppModule to perform URL rewriting
 * before the application loads.
 *
 * @param {UrlRewriterService} urlRewriterService - The service responsible for URL rewriting.
 * @returns {Function} A function to be executed during application initialization.
 */
export function initializeApp(
  urlRewriterService: UrlRewriterService,
): () => void {
  return () => urlRewriterService.rewriteUrl();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
    SearchComponent,
    HomeComponent,
    CallToActionsComponent,
    MaintenanceComponent,
    SearchResultsComponent,
    AuthorControlsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BannerComponent,
    CardComponent,
    BrowserAnimationsModule,
    HttpClientModule,
    MatIconModule,
    MatButtonModule,
    RichTextPipe,
    HeroBannerComponent,
    LoaderComponent,
    PageNotFoundComponent,
    MatListModule,
    MatCardModule,
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SelfServeHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ActivationHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    UrlRewriterService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [UrlRewriterService],
      multi: true,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {
}
