<app-hero-banner
  [hero]="hero"
>
</app-hero-banner>

<div class="container">
  <app-card
    *ngFor="let card of cards"
    [imageUrl]="card.fields.tileImage?.fields?.file?.url"
    [heading]="card.fields.title"
    [description]="card.fields.content | richText"
    [callToActionsTable]="card.fields.callToActions"
    [loginToLearnMore]="isAuthenticated ? false : !card.fields.callToActionRequiresAuthentication"
    [footerImageUrl]="card.fields.footerImage?.fields?.file?.url"
  >
  </app-card>
</div>
