import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ContentService } from '../../content.service';
import { AuthenticationService } from '../../authentication.service';
import { NAV_CONTENT_ID } from '../../shared/constants';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean = false;

  isSticky: boolean = false;

  private authSubscription!: Subscription;

  iAmHereChildren!: any[];

  edrChildren!: any[];

  valuedBenefitsChildren!: any[];

  selfServeChildren!: any[];

  constructor(
    private authService: AuthenticationService,
    private contentService: ContentService,
  ) {}

  ngOnInit() {
    this.authSubscription = this.authService.isSsoAuthenticated$.subscribe(
      (isAuth: boolean) => {
        this.isAuthenticated = isAuth;
      },
    );

    this.contentService
      .getContentById(NAV_CONTENT_ID)
      .subscribe((result) => {
        this.iAmHereChildren = result.fields.iAmHere;
        this.valuedBenefitsChildren = result.fields.valuedBenefits;
        this.edrChildren = result.fields.everydayRewardsPlus;

        this.updateNavigationItems();
      });
  }

  private updateNavigationItems() {
    this.mainNavigationItems[0].children = this.iAmHereChildren.map((x) => ({
      name: x.fields.label,
      url: x.fields.url,
      link: `i-am-here/${x.fields.slug}`,
      isExternal: x.fields.linkType === 'External link',
      newTab: x.fields.openInNewTab,
    }));
    this.mainNavigationItems[1].children = this.valuedBenefitsChildren.map(
      (x) => ({
        name: x.fields.label,
        url: x.fields.url,
        link: `valued-benefits/${x.fields.slug}`,
        isExternal: x.fields.linkType === 'External link',
        newTab: x.fields.openInNewTab,
      }),
    );
    this.mainNavigationItems[2].children = this.edrChildren.map((x) => ({
      name: x.fields.label,
      url: x.fields.url,
      link: `everyday-rewards-plus/${x.fields.slug}`,
      isExternal: x.fields.linkType === 'External link',
      newTab: x.fields.openInNewTab,
    }));
  }

  ngOnDestroy() {
    // Always unsubscribe to prevent memory leaks
    this.authSubscription.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isSlideOutNavOpen = false;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const headerHeight = 85;
    this.isSticky = window.pageYOffset > headerHeight;
  }

  public isSlideOutNavOpen: boolean = false;

  onMouseOver(parent: any): void {
    const child = parent.querySelector('.dropdown');
    if (child) {
      child.style.display = 'block';
    }
  }

  onMouseOut(parent: any): void {
    const child = parent.querySelector('.dropdown');
    if (child) {
      child.style.display = 'none';
    }
  }

  hideDropdown(parent: any): void {
    const dropdown = parent.querySelector('.dropdown');
    if (dropdown) {
      dropdown.style.display = 'none';
    }
  }

  mainNavigationItems = [
    {
      name: 'I am here',
      description: `Welcome to the I am here wellbeing portal, your one-stop place for support.
      Our wellbeing is part of every aspect of our lives, and it’s important that we take care of
      it and look out for each other.`,
      routerLink: '/i-am-here',
      children: [],
    },
    {
      name: 'Valued benefits',
      description:
        "You're a valued part of the Woolworths Group team. That's why it's important to us that you are looked after, with working benefits that go beyond your pay.",
      routerLink: '/valued-benefits',
      children: [],
    },
    {
      name: 'Everyday Rewards Plus',
      description:
        'Enjoy more value as a team member with your Everyday Rewards Plus Card.',
      routerLink: '/everyday-rewards-plus',
      children: [],
    },
    {
      name: 'Team Discount Self Serve',
      description:
        'Welcome to the Team Discount Self Serve Portal, your one-stop place to request, activate and view T&Cs for the Everyday Rewards Plus Card.',
      routerLink: '/team-discount-selfserve',
      children: [
        {
          name: 'Manage your Everyday Rewards Plus Card',
          link: 'team-discount-selfserve',
          isExternal: false,
          url: undefined,
          newTab: false,
        },
        {
          name: 'FAQs - Everyday Rewards Plus and Team Discount Card',
          link: 'team-discount-selfserve/faqs',
          isExternal: false,
          url: undefined,
          newTab: false,
        },
      ],
    },
  ];
}
