<section class="banner">
<div class="top-hero__landing-gradient">
  <div class="top-hero" [style.background-color]="bgColor" [style.background-image]="'url(' + imageUrl + ')'">
   <div class="top-hero__wrapper">
    <div class="top-hero__wrapper__inner">
      <div class="banner-content">
        <h1>{{ heading }}</h1>
        <p>{{ description }}</p>
        <ng-content></ng-content>
      </div>
    </div>
   </div>
  </div>
 </div>
</section>
