<div class="search-results-container">
  <h2 mat-subheader>Search Results</h2>
  <mat-list *ngIf="searchResults.length > 0">
    <mat-card *ngFor="let result of searchResults" class="search-result-card">
      <mat-card-content (click)="onResultSelect(result)">
        <mat-icon>description</mat-icon>
        <span class="bold">{{ result.title }}</span>
        <div class="arrow-icon">
          <mat-icon>arrow_forward</mat-icon>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-list>
  <div *ngIf="searchResults.length === 0">
    <p>There were no results matching your search.</p>
  </div>
</div>
