import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UrlRewriterService {
  constructor(private router: Router) {}

  rewriteUrl() {
    const currentUrl = window.location.pathname;
    const params = new URLSearchParams(window.location.search);

    let newUrl = currentUrl;

    if (newUrl.endsWith('.html')) {
      newUrl = newUrl.slice(0, -5); // Remove the .html extension
    }

    if (newUrl.startsWith('/content/teambenefits/en/home')) {
      newUrl = newUrl.slice(29); // Remove the AEM content prefix from URL
    }

    if (newUrl.startsWith('/nz-link-callback')) {
      newUrl = '/card-activation/nz-link-callback';
    }

    if (
      newUrl.startsWith('/team-discount-selfserve/terms-conditions-new-zealand')
    ) {
      newUrl = '/team-discount-selfserve/terms-conditions';
      params.set('country', 'NZ');
    }

    if (
      newUrl.startsWith('/team-discount-selfserve/terms-conditions-australia')
    ) {
      newUrl = '/team-discount-selfserve/terms-conditions';
      params.set('country', 'AU');
    }

    if (newUrl.startsWith('/team-discount-selfserve/terms-conditions-asia')) {
      newUrl = '/team-discount-selfserve/terms-conditions';
      params.set('country', 'OT');
    }

    this.router.navigateByUrl(`${newUrl}?${params.toString()}`);
  }
}
