import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  currentYear: number = new Date().getFullYear();

  moreInWooliesLinks = [
    {
      link: 'https://www.woolworthsgroup.com.au/page/community-and-responsibility/group-responsibility/our-people/',
      label: 'Our People',
    },
    {
      link: 'https://www.woolworthsgroup.com.au/page/community-and-responsibility/group-responsibility/responsible-sourcing/',
      label: 'Our Customers and Community',
    },
    {
      link: 'https://www.woolworthsgroup.com.au/page/community-and-responsibility/group-responsibility/environment/',
      label: 'Our Planet',
    },
    {
      link: 'https://www.woolworthsgroup.com.au/page/community-and-responsibility/group-responsibility/partners/',
      label: 'Our Partners',
    },
  ];

  relatedSitesLinks = [
    {
      link: 'https://www.woolworthsgroup.com.au',
      label: 'Woolworths Group',
    },
    {
      link: 'https://www.wowcareers.com.au/',
      label: 'Woolworths Group Careers',
    },
  ];

  aboutLinks = [
    {
      link: 'https://www.woolworthsgroup.com.au/privacy',
      label: 'Privacy at Woolworths Group',
    },
  ];
}
