import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ITbCategoryPageFields, ITbPagesMainCategory, ITbPagesMainCategoryFields } from '../shared/interfaces/contentful';
import { ContentService } from '../content.service';
import { HOME_CONTENT_ID } from '../shared/constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  fields!: ITbPagesMainCategoryFields;

  cards: any;

  private contentSubscription: Subscription | undefined;

  constructor(private activatedRoute: ActivatedRoute, private contentService: ContentService) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe((data: any) => {
      const { fields } = data?.content || {};
      this.fields = fields;
      this.cards = fields.cards;
    });

    this.contentService.previewsVisible.subscribe((enabled) => {
      if (enabled) {
        this.contentSubscription = this.contentService.getContentById(HOME_CONTENT_ID, 2).subscribe((data) => {
          const { fields } = data || {};
          this.fields = fields;
          this.cards = fields.cards;
        });
      } else {
        this.contentSubscription?.unsubscribe();
      }
    });
  }

  ngOnDestroy(): void {
    this.contentSubscription?.unsubscribe();
  }
}
