import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ITbContentBlocksLink } from '../../shared/interfaces/contentful';

@Component({
  standalone: true,
  selector: 'app-card',
  templateUrl: './card.component.html',
  imports: [CommonModule],
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() imageUrl?: string;

  @Input() heading?: string;

  @Input() description?: string;

  @Input() callToActionsTable?: ITbContentBlocksLink[];

  @Input() loginToLearnMore: boolean = false;

  @Input() footerImageUrl?: string;

  showSingleCTA: boolean = true;

  constructor() {}

  ngOnInit() {
    this.parseCallsToAction();
  }

  getURLprefix(categoryName: string) {
    switch (categoryName) {
      case 'tbPagesMainCategoryPage':
        return '';
      case 'tbCategoryPage':
        return 'i-am-here/';
      case 'tbValuedBenefitsPage':
        return 'valued-benefits/';
      case 'tbEdr':
        return 'everyday-rewards-plus/';
      default:
        return 'content/';
    }
  }

  getURL(fields: any): string {
    if (fields && 'externalLinkUrl' in fields) {
      return fields.externalLinkUrl;
    }

    if (fields && 'content' in fields) {
      const typeId: string = fields.content.sys.contentType.sys.id;
      const prefix: string = this.getURLprefix(typeId);
      return `${prefix}${fields.content?.fields?.slug}`;
    }
    return '';
  }

  parseCallsToAction() {
    if (this.callToActionsTable) {
      if (this.callToActionsTable.length !== 1) {
        this.showSingleCTA = false;
      }
    }
  }
}
