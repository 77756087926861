import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  apigee_sdc_base_url: 'https://api.woolworthsrewards.com.au',
  apigee_sdc_clientid: '7eJxvAN2n6k2x0vUSgLR6L0NMhtFZ70b',
  edrLoginUrl: 'https://www.everydayrewards.co.nz/partner-link?partnerid=2009',
  googleClientId: '576851862194-qci3muhl8ad5u9voigu8qlmv41u3dnfs.apps.googleusercontent.com',
  enablePreview: false,
  baseUrl: '',
  contentful: {
    spaceId: 'gbhr96tejqwq',
    environment: 'master',
    api_string: 'CFPAT-7BWH0nDBnfCLtjUvM7O42KROjgCYqOS0o_qj1_oqtOE',
  },
};
