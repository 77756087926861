<div class="header-container">
  <div class="header-column  mobileHide" >
    <a [routerLink]="'home'">
      <img class="logo-img" src="assets/logo.svg" width="150" height="40" />
    </a>
  </div>

  <div class="header-column team-benefits mobileHide">Team Benefits</div>
  <div class="header-column mobileHide">
   <app-search *ngIf="isLoggedIn"></app-search>
  </div>
  <div class="header-column">
    <button *ngIf="isLoggedIn" mat-raised-button color="primary" id="logout-button" (click)="logout()">Log Out</button>
    <div [class.hide]="isLoggedIn" class="signInButton" id="google-button"></div>
  </div>
</div>
