import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { handleError } from 'src/app/sdc/utils/error.handler';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { api_url, TOKEN_EXPIRY } from '../utils/constant.helper';
import { LoginService } from '../services/login.service';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../authentication.service';

@Injectable()
export class SelfServeHttpInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
    private loginService: LoginService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('/api/content') || !this.router.routerState.snapshot.url.includes('team-discount-selfserve')) {
      return next.handle(request);
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      client_id: environment.apigee_sdc_clientid,
      Authorization: `Bearer ${this.authService.getTokenValue('default')}`,
    });

    let modifiedRequest = request.clone({ headers });
    if (
      request.url.indexOf(api_url.OTP_REGISTER) !== -1
      || request.url.indexOf(api_url.OTP_VERIFY) !== -1
      || request.url.indexOf(api_url.LANDING_VALIDATION) !== -1
    ) {
      modifiedRequest = request;
    }
    return next.handle(modifiedRequest).pipe(
      tap(
        () => {},
        (error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              const errorMessage = handleError(error).message;
              if (errorMessage === TOKEN_EXPIRY) this.loginService.updateAccessTokenExpiry(true);
            }
          }
        }
      )
    );
  }
}
