import { Pipe, PipeTransform } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

@Pipe({
  name: 'richText',
  standalone: true,
})
export class RichTextPipe implements PipeTransform {
  transform(value: any): string {
    const appRenderRichText = documentToHtmlString(value);
    let renderedHtml;
    // // Wrap content in a div with a top-level class
    // return `<div class="contentful-content">${renderedHtml}</div>`;
    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
          if (node.data.target.fields.file.contentType.includes('image')) {
            return `<img class="richTextImage" src="${node.data.target.fields.file.url}" alt=${node.data.target.fields.file.description}>`;
          }

          if (node.data.target.fields.file.contentType.includes('vide')) {
            return ` <video class="richTextVideo" controls width="100%" height="100%" [muted]="true" src="${node.data.target.fields.file.url}"  loop="true" muted="true" autoplay="false">
            </video>`;
          }

          return '';
        }
      },
    };
    if (appRenderRichText) {
      renderedHtml = documentToHtmlString(value, options);
    } else {
      renderedHtml = '';
    }
    return `<div class="contentful-content">${renderedHtml}</div>`;
  }
}
