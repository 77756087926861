import { Component, Input } from '@angular/core';
import {
  ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router 
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { IMaintenance } from '../../interfaces/contentful';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {
  constructor(private activatedRoute: ActivatedRoute, private titleService: Title) {}

  maintenancePageData!: IMaintenance;

  ngOnInit() {
    this.activatedRoute.data.subscribe((data: any) => {
      this.maintenancePageData = data.content;
      this.titleService.setTitle('Maintenance Page');
    });
  }
}
