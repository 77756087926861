import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ContentService } from 'src/app/content.service';
import { ITbCard, ITbValuedBenefitsHero } from '../../shared/interfaces/contentful';
import { AuthenticationService } from '../../authentication.service';

@Component({
  selector: 'app-iam-home-child',
  templateUrl: './iam-home-child.component.html',
  styleUrls: ['./iam-home-child.component.scss']
})
export class IamHomeChildComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean = false;

  private authSubscription!: Subscription;

  hero!: ITbValuedBenefitsHero;

  cards: ITbCard[] = [];

  private contentSubscription: Subscription | undefined;

  constructor(
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private contentService: ContentService
  ) {}

  ngOnInit() {
    const slug = this.activatedRoute.snapshot.paramMap.get('slug') || '';

    this.authSubscription = this.authService.isSsoAuthenticated$.subscribe(
      (isAuth: boolean) => {
        this.isAuthenticated = isAuth;
      },
    );

    this.activatedRoute.data.subscribe((data: any) => {
      const { fields } = data?.content || {};
      const { title } = fields;
      this.hero = fields.hero;
      this.cards = fields.cards;
      this.titleService.setTitle(`Team Benefits | ${title}`);
    });

    this.contentService.previewsVisible.subscribe((enabled) => {
      if (enabled) {
        this.contentSubscription = this.contentService.getContentBySlug('tbCategoryPage', slug, 2).subscribe((data) => {
          const { fields } = data || {};
          this.hero = fields.hero;
          this.cards = fields.cards;
        });
      } else {
        this.contentSubscription?.unsubscribe();
      }
    });
  }

  ngOnDestroy() {
    // Always unsubscribe to prevent memory leaks
    this.authSubscription.unsubscribe();
  }
}
