<nav class="sticky-navigation">
  <section class="mobile-navigation">
    <button
      id="toggleMenuBtn"
      (click)="isSlideOutNavOpen = !isSlideOutNavOpen"
      class="nav-items"
      [class.open]="isSlideOutNavOpen"
    >
      <mat-icon
        aria-hidden="false"
        aria-label="Menu icon"
        fontIcon="menu"
      ></mat-icon>
    </button>
    <button
      id="toggleCloseBtn"
      (click)="isSlideOutNavOpen = false"
      aria-label="closeNav"
      class="nav-items closeBtn"
      [class.open]="isSlideOutNavOpen"
    >
      <mat-icon
        aria-hidden="false"
        aria-label="Menu icon"
        fontIcon="close"
      ></mat-icon>
    </button>
    <a class="navbar-brand" [routerLink]="'home'">
      <img class="logo-img" src="assets/logo.svg" width="150" height="40" />
    </a>
  </section>


  <div class="nav-items navItemsContainer">
    <a class="navbar-brand-sticky" [routerLink]="'home'" [class.show]="isSticky">
      <img class="logo-img" src="assets/logo.svg" width="150" height="40"/>
    </a>
    <div class="slideOutNavItemAuth" *ngFor="let item of mainNavigationItems"
         #parentDiv
         (mouseover)="onMouseOver(parentDiv)"
         (mouseout)="onMouseOut(parentDiv)">
      <a
        *ngIf="!isAuthenticated"
        [routerLink]="item.routerLink"
        class="navigation-link"
      >{{ item.name }}</a
      >

      <!-- Dropdown for authenticated users -->
      <ng-container *ngIf="isAuthenticated">
        {{ item.name }}
        <div class="dropdown" >
          <div class="flex-container">
            <div class="flex-column max-width-300">
              <div>{{ item.description }}</div>
              <a [routerLink]="item.routerLink" (click)="hideDropdown(parentDiv)" class="btn">Learn more</a>
            </div>
            <div class="flex-column">
              <div class="two-columns-div">
                <div *ngFor="let child of item.children" class="dropdown-item">
                  <a
                    *ngIf="!child.isExternal"
                    [routerLink]="child.link"
                    class="child-name"
                    [attr.target]="child.newTab ? '_blank' : '_self'"
                    [attr.rel]="child.newTab ? 'noopener noreferrer' : null"
                    (click)="hideDropdown(parentDiv)"
                  >{{ child.name }}</a
                  >
                  <a
                    *ngIf="child.isExternal"
                    [href]="child.url"
                    [attr.target]="child.newTab ? '_blank' : '_self'"
                    [attr.rel]="child.newTab ? 'noopener noreferrer' : null"
                    (click)="hideDropdown(parentDiv)"
                    class="child-name"
                  >{{ child.name }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</nav>
<nav class="navSlideOut" [class.open]="isSlideOutNavOpen">
  <div class="slideOutNavItemsContainer">
    <div class="navbarNav">
      <div class="slideOutNavItem" *ngFor="let item of mainNavigationItems">
        <a
          *ngIf="!isAuthenticated"
          [routerLink]="item.routerLink"
          class="categoryName"
          (click)="isSlideOutNavOpen = false"
        >{{ item.name }}</a
        >
        <ng-container *ngIf="isAuthenticated">
          <span class="categoryName">{{ item.name }}</span>
          <div class="navSlideDropdown">
            <div class="navSlideHeading">
              <p class="caption">{{ item.description }}</p>
            </div>
            <a class="learnMore" [routerLink]="item.routerLink" class="btn"
            >Learn more</a
            >

            <ul *ngIf="item.children?.length">
              <li class="subLink" *ngFor="let child of item.children">
                <a
                  *ngIf="child.isExternal"
                  [href]="child.url"
                  (click)="isSlideOutNavOpen = false"
                >{{ child.name }}</a
                >
                <a
                  *ngIf="!child.isExternal"
                  [routerLink]="child.link"
                  class="child-name"
                  (click)="isSlideOutNavOpen = false"
                >{{ child.name }}</a
                >
              </li>
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</nav>
