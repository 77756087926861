const commonLabels = {
  SUBMIT: 'Submit',
  CONTINUE: 'Continue',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  DATE_OF_BIRTH: 'Date of Birth',
  CARD_NUMBER_ERROR: 'Enter your 13 digit Everyday Rewards Plus card number',
};

export const LABELS = {
  stepper: {
    LOGIN: 'Login',
    TERMS_CONDITIONS: 'Terms and Conditions',
    MEMBER_TYPE: 'Member Type',
    MEMBER_UPDATE: 'Member Update',
    SET_PASSWORD: 'Set Password',
    CONFIRMATION: 'Confirmation'
  },
  breadCrumb: {
    TERMS: 'Terms and Condition',
    MEMBER_TYPE: 'Member Selection',
    MEMBER_UPDATE: 'Update Details',
  },
  validation: {
    VALIDATION_HEADER: 'Everyday Rewards Plus Card',
    VALIDATION_SUB_HEADER: 'Activate your new card',
    VALIDATION_DESCRIPTION:
      ' Get your exclusive team discount plus all the amazing benefits of Everyday Rewards with your Everyday Rewards Plus Card.',
    EMPLOYEE_NUMBER: 'Employee Number',
    CARD_NUMBER: 'Card Number',
    VALIDATION_SUBMIT: 'Activate Now',
    CARD_NUMBER_ERROR: commonLabels.CARD_NUMBER_ERROR,
    CARD_NUMBER_TOOLTIP:
      'Enter your 13 digit Everyday Rewards Plus card number',
    EMPLOYEE_NUMBER_ERROR:
      'Please enter alphanumerics only and must not exceed 20',
    EMPLOYEE_NUMBER_TOOLTIP: 'Enter your Employee number',
    IMAGE: '/assets/box-pluscard-910-6081.jpg',
    MISSING_MOBILE_EMAIL_ERROR: 'We are unable to process this request as we hold invalid email and/or mobile phone number information. Please refer to FAQ\'s on how to update your personal information.'
  },
  memberType: {
    MEMBER_TYPE_HEADER: 'Are you an existing Everyday Rewards member?',
    MEMBER_TYPE_DESCRIPTION:
      'Please enter card holder’s details as they appear in the Everyday Rewards account',
    NOT_MEMBER: 'Not an Everyday Rewards member?',
    MEMBER_TYPE_FIRST_NAME: commonLabels.FIRST_NAME,
    MEMBER_TYPE_LAST_NAME: commonLabels.LAST_NAME,
    MEMBER_TYPE_CARD_NO: 'Existing Card Number',
    MEMBER_TYPE_DOB: commonLabels.DATE_OF_BIRTH,
    MEMBER_TYPE_SUBMIT: commonLabels.SUBMIT,
    MEMBER_TYPE_CONTINUE: commonLabels.CONTINUE,
    MEMBER_TYPE_FIRST_NAME_ERROR:
      'Please enter First Name. Alphabets, space, dot, quote and hyphens are only allowed',
    MEMBER_TYPE_LAST_NAME_ERROR:
      'Please enter Last Name. Alphabets, space, dot, quote and hyphens are only allowed',
    MEMBER_TYPE_CARD_NO_ERROR: commonLabels.CARD_NUMBER_ERROR,
    MEMBER_TYPE_DOB_ERROR: 'Please enter Date of Birth',
  },
  memberUpdate: {
    MEMBER_HEADER: 'Your Details',
    MEMBER_DESCRIPTION:
      "For existing Everyday Rewards /Everyday Rewards Plus card holders we'll transfer all your rewards to your new Everyday Rewards Plus card",
    MEMBER_FIRST_NAME: 'First Name',
    MEMBER_LAST_NAME: 'Last Name',
    MEMBER_DOB: 'Date of Birth',
    MEMBER_GENDER: 'Gender',
    MEMBER_EMAIL: 'Email Address',
    MEMBER_MOBILE: 'Contact Number',
    MEMBER_RESENDENTIAL_ADDRESS: 'Address',
    MEMBER_PAYROLL_SYSTEM: ' (Payroll System)',
    MEMBER_MAILING_ADDRESS: 'Mailing Address',
    MEMBER_UPDATE_HEADER: 'Preferred Contact Details',
    MEMBER_UPDATE_DESCRIPTION: 'Everyday Rewards will send messages to the Preferred Contact Details below.',
    MEMBER_UPDATE_ADDRESS_UNIT: 'Unit Number',
    MEMBER_UPDATE_ADDRESS_UNIT_ERROR:
      'Please enter unit number. Alphabets, space, forward slash, numbers and hyphens are only allowed',
    MEMBER_UPDATE_ADDRESS_STREET_NO: 'Street Number',
    MEMBER_UPDATE_ADDRESS_STREET_ERROR:
      'Please enter street number. Alphabets, space, forward slash, numbers and hyphens are only allowed',
    MEMBER_UPDATE_ADDRESS_STREET_NAME: 'Street Name',
    MEMBER_UPDATE_ADDRESS_STREET_NAME_ERROR:
      'Please enter street name. Alphabets, quotes, hyphens, spaces are only allowed',
    MEMBER_UPDATE_ADDRESS_SUBURB: 'Suburb',
    MEMBER_UPDATE_ADDRESS_SUBURB_ERROR:
      'Please enter suburb. Alphabets, spaces are only allowed',
    MEMBER_UPDATE_ADDRESS_POSTCODE: 'Postcode',
    MEMBER_UPDATE_ADDRESS_POSTCODE_ERROR:
      'Please enter postcode. Numbers are only allowed',
    MEMBER_UPDATE_ADDRESS_STATE: 'State',
    MEMBER_UPDATE_ADDRESS_STATE_ERROR: 'Please select a state.',
    MEMBER_PREFERRED_MAIL: 'Preferred Email Address',
    MEMBER_PREFERRED_MAIL_ERROR: 'Please enter a valid email address.',
    MEMBER_PREFERRED_MOBILE: 'Preferred Contact Number',
    MEMBER_PREFERRED_MOBILE_ERROR: 'Please enter a valid mobile number.',
    MEMBER_PASSWORD_HEADER: 'Please confirm your password',
    MEMBER_PASSWORD_DESCRIPTION:
      'Use this password to log into your Everyday Rewards account online. Here you can track your rewards, see current offers, update your details and more.',
    MEMBER_PASSWORD: 'Password',
    MEMBER_CONFIRM_PASSWORD: 'Confirm Password',
    MEMBER_PASSWORD_MISMATCH: 'Passwords do not match.',
    PASSWORD_TOOLTIP:
      'Your password must be 8-20 characters long and contain atleast one uppercase,one lowercase and one number. This will help us keep your account safe.',
    MEMBER_OFFER_CHECKBOX:
      'I would like to receive offers exclusive to team members',
    MEMBER_OFFER_CHECKBOX_TERMS:
      'By selecting this option, you agree to us sending you offers exclusive to Everyday Rewards Plus members. If you do not wish to receive exclusive member offers you may select to opt-out.',
    MEMBER_SUBMIT: commonLabels.SUBMIT,
    MEMBER_SF_TERMS:
      ' *If your mailing address is no longer correct, please update via <a target="_blank" href="https://performancemanager10.successfactors.com/login?company=Woolworths"> SuccessFactors</a>',
  },
  confirmation: {
    CONFIRMATION_YOUR_CARD: 'Your Digital Card',
    CONFITMATION_ACTIVATED: 'is activated',
    CONFIRMATION_DESCRIPTION:
      ' If you had an existing Everyday Rewards Card, we have transferred your points boosters, extras and communication preferences to your new Everyday Rewards Plus Card. Your old Everyday Rewards Card number is now cancelled. Please add your new Everyday Rewards Plus Card to your digital device. If you would like to update your Everyday Rewards communication preferences, please go to Everyday Rewards linked below and log into your Everyday Rewards account.',
    CONFIRMATION_HERO_IMAGE: '/assets/confirm-hero-image.png',
    CONFIRMATION_ARTICLES: [
      {
        icon: '/assets/icon-pluscard.png',
        title: 'SHARE THE BENEFITS',
        description:
          'To find out more about applying for a secondary Everday Rewards Plus card, please visit the Team Benefits Portal.',
        links: [
          {
            text: 'Find out more',
            link: 'https://teambenefits.woolworths.com.au/plus-card.html',
          },
        ],
      },
      {
        icon: '/assets/icon-check.png',
        title: 'IMPORTANT INFOMATION ABOUT YOUR EVERYDAY REWARDS PLUS CARD',
        description:
          "Now that you're all set up with your new Everday Rewards Plus card, you'll be able to use your new card number to login into your Everyday Rewards Account. Alternatively, you can use the email address you just used to set up your account.",
        links: [
          {
            text: 'Add to Digital Wallet',
            link: 'https://www.woolworthsrewards.com.au/digital-wallet-login.html',
          },
          {
            text: 'Add to ER App',
            link: 'https://www.woolworthsrewards.com.au/app.html',
          },
        ],
      },
      {
        icon: '/assets/icon-personal.png',
        title: 'SEE YOUR EXCLUSIVE OFFERS',
        description: 'If you would like to track your points activity, extras, or update your Everyday Rewards communication preferences, please go to the Everyday Rewards link below and log into your Everyday Rewards account.',
        links: [
          {
            text: 'Go to Everyday  Rewards',
            link: 'http://www.woolworthsrewards.com.au/',
          },
        ],
      },
    ],
  },
};
