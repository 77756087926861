import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AllowDateTimeOnlyDirective } from './directives/allow-date-time-only.directive';
import { SearchAllowedCharactersDirective } from './directives/search-allowed-characters.directive';

@NgModule({
  declarations: [
    AllowDateTimeOnlyDirective,
    SearchAllowedCharactersDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AllowDateTimeOnlyDirective,
    SearchAllowedCharactersDirective
  ],
  providers: [
    DatePipe
  ]
})
export class SharedModule { }
