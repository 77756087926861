<section class="banner">
  <div class="top-hero__landing-gradient">
    <ng-container *ngIf="!twoColum; else twoColumn">
      <div
        class="top-hero"
        [style.background-image]="
          'url(' + hero?.fields?.media?.fields?.file?.url + ')'
        "
      >
        <div class="top-hero__wrapper">
          <div class="top-hero__wrapper__inner">
            <div class="banner-content">
              <h1>{{ hero?.fields?.title }}</h1>
              <p>{{ hero?.fields?.leadParagraph }}</p>
              <p>{{ hero?.fields?.secondaryParagraph }}</p>
              <span *ngIf="hero?.fields?.footerIcon?.fields?.file?.url">
                <img
                  class="footerIcon"
                  [alt]="hero?.fields?.footerIcon?.fields?.description"
                  [src]="hero?.fields?.footerIcon?.fields?.file?.url"
                />
              </span>
              <div
                *ngIf="
                  hero?.fields?.callToActionUrl && hero?.fields?.callToAction
                "
              >
                <a
                  [href]="hero?.fields?.callToActionUrl"
                  target="_blank"
                  class="btn-primary"
                  >{{ hero?.fields?.callToAction }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>
<ng-template #twoColumn>
  <div class="two-column-parent">
    <div class="container">
      <div class="column two-column-content">
        <div class="banner-content">
          <h1>{{ hero?.fields?.title }}</h1>
          <p class="margin-auto">{{ hero?.fields?.leadParagraph }}</p>
          <p class="margin-auto">{{ hero?.fields?.secondaryParagraph }}</p>
          <div class="hero-footer">
            <div *ngIf="hero?.fields?.footerIcon?.fields?.file?.url">
              <img
                class="footerIcon hero-box__detail"
                [alt]="hero?.fields?.footerIcon?.fields?.description"
                [src]="hero?.fields?.footerIcon?.fields?.file?.url"
              />
            </div>
            <div
              class="hero-box__detail button-link"
              *ngIf="
                hero?.fields?.callToActionUrl && hero?.fields?.callToAction
              "
            >
              <a
                [href]="hero?.fields?.callToActionUrl"
                target="_blank"
                class="button--outline"
                >{{ hero?.fields?.callToAction }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div
          *ngIf="
            checkImageType(hero?.fields?.media?.fields?.file?.contentType);
            else textVideoContent
          "
        >
          <div
            class="side-hero"
            [style.background-image]="
              'url(' + hero?.fields?.media?.fields?.file?.url + ')'
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #textVideoContent>
  <!-- video bg -->
  <video
    class="textVideo"
    controls
    width="100%"
    height="100%"
    [muted]="true"
    [src]="hero?.fields?.media?.fields?.file?.url"
    loop="true"
    muted="true"
    autoplay="true"
  ></video>
</ng-template>
