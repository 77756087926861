import { LABELS } from './labels.helper';

export const tooltip_delay = {
  SHOW_DELAY: 100,
  HIDE_DELAY: 2000,
};

export const regex = {
  CARD_NUMBER_REGEX: '^[0-9]{13}$',
  EMPLOYEE_NUMBER_REGEX: '^[a-z0-9A-Z]{1,20}$',
  NAME_REGEX: "(^[A-Za-z\\s.'-]+$)",
  NUMBER_REGEX: '^[0-9]+$',
  NUMBER_ALPHABETS_HYPHENS_REGEX: '^[0-9-a-zA-Z/ ]+$',
  ALPA_SPACE_HYPHENS_REGEX: "^[0-9A-Za-z-'/ ]+$",
  ALPABETS_REGEX: '^[A-Za-z ]+$',
  PASSWORD_REGEX: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$',
  MOBILE_NUMBER_REGEX: '^[+]*[0-9]{1,4}[0-9]*$',
  EMAIL_REGEX:
  // eslint-disable-next-line
    '(^[_A-Za-z0-9-]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,4})$)',
};

export const api_url = {
  LANDING_VALIDATION: '/wx/v1/loyalty/sdc/self-serve/employees/login',
  OTP_REGISTER: '/wx/v1/loyalty/sdc/self-serve/employees/one-time-passcode',
  OTP_VERIFY: '/wx/api-otp/verifytoken/sdcregister',
  EMPLOYEE_SEARCH: '/wx/v1/loyalty/sdc/self-serve/employees/search',
  UPDATE_ADDRESS: '/wx/v1/loyalty/sdc/self-serve/employees/addresses',
  REFERENCES: '/wx/v1/loyalty/sdc/references/',
  REPLACE_CARD: '/wx/v1/loyalty/sdc/self-serve/employees/replace-card',
  APPLY_SEONDARY_CARD:
    '/wx/v1/loyalty/sdc/self-serve/employees/apply-for-secondary-card',
  CANCEL_SECONDARY_CARD: '/wx/v1/loyalty/sdc/self-serve/employees/cancel-card',
  RESEND_EMAIL: '/wx/v1/loyalty/sdc/self-serve/employees/send-confirmation-email'
};

export const GENERIC_ERROR = 'Something went wrong! Please try again later.';
export const NZ_INVALID_USER = 'We are unable to process your request please email staffdisc@countdown.co.nz for assistance';
export const NZ_ACTIVATION_ERROR = 'You cannot use this portal to activate your card. Please contact staffdisc@countdown.co.nz for assistance';
export const ASIA_ERROR = 'If you are an Asia Team member, Please contact  Asia People Services Team for assistance';
export const TOKEN_EXPIRY = 'The access token is expired';
export const TOKEN_EXPIRY_MESSAGE = 'Session expired. Please login';
export const ADDRESS_MISMATCH_AU = 'Please note your address does not match your secondary card holder’s address.  Under the terms and conditions of use of Everyday Rewards Plus Card policy, your secondary cardholder must be an immediate family member residing at the same address as you. Please click here for <a target="_blank" href="https://teambenefits.woolworths.com.au/team-discount-selfserve/faqs.html">FAQ’s</a>.';
export const ADDRESS_MISMATCH_NON_AU = 'Please note your address does not match your secondary card holder’s address. Under the terms and conditions of use of Team Discount Cards policy, your secondary cardholder must be an immediate family member residing at the same address as you. Please click here for <a target="_blank" href="https://teambenefits.woolworths.com.au/team-discount-selfserve/faqs.html">FAQ’s</a>.';
export const ADDRESS_MISMATCH_SY_AU = 'We are unable to proceed with your request for a Secondary Everyday Rewards Plus Card at this time. Under the Everyday Rewards Plus Card Policy your secondary cardholder must be an immediate family member residing at the same address as you. Please click here for <a target="_blank" href="https://teambenefits.woolworths.com.au/team-discount-selfserve/faqs.html">FAQ’s</a>.';

export const CARD_ACTIVATION_ACTION = 'Card Activation';
export const CARD_ACTIVATION_LINK = '/activation.html';
export const NZ_COUNTRY_CODE = 'NZL';
export const AU_COUNTRY_CODE = 'AUS';
export const AU_COUNTRY_CODE_NO = '9';
export const selfserve_labels = LABELS;

export const ACTIONS = {
  'Apply For Secondary Card': 'apply-for-secondary-card',
  'Card Activation': 'card-activation',
  'Cancel Secondary Card': 'cancel-secondary-card',
  'Replace Primary Card': 'replace-primary-card',
  'Replace Secondary Card': 'replace-secondary-card'
};

export const GENDER = [
  { code: 'F', name: 'Female' },
  { code: 'M', name: 'Male' },
  { code: 'U', name: 'Prefer not to say' },
];
export const MAX_DATE = new Date();
export const MIN_DATE = new Date(
  new Date().setFullYear(new Date().getFullYear() - 100)
);

export const COUNTRY = {
  AU: 'Australia',
  NZ: 'New Zealand',
  OT: 'Others',
};

export const MALE_CATEGORY = ['Father', 'Sir', 'Mr.', 'Master', 'Father', 'Brother', 'Step son', 'Son'];
export const FEMALE_CATEGORY = ['Lady', 'Miss', 'Mrs.', 'Ms.', 'Sister', 'Mother', 'Sister', 'Step daughter', 'Daughter'];
