import { Component } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'wx-davinci-teambenefits-fe';

  constructor(
    private router: Router,
    private titleService: Title,
  ) {
    const isProd = window.location.hostname === 'teambenefits.woolworths.com.au';
    let analyticsKey = 'GTM-5J2RNNB';

    if (isProd) {
      analyticsKey = 'GTM-T9DCV9K';
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', analyticsKey, {
          page_path: event.urlAfterRedirects,
          page_title: this.titleService.getTitle(),
        });
      }
    });
  }
}
