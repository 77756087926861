import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appSearchAllowedChars]'
})
export class SearchAllowedCharactersDirective {
  // eslint-disable-next-line
  private regex: RegExp = new RegExp(/^[a-zA-Z0-9 ]*$/);

  constructor(private control: NgControl) { }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (!this.regex.test(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData?.getData('text/plain') ?? '';
    const filteredInput = pastedInput.split('').filter((char) => this.regex.test(char)).join('');

    if (this.control && this.control.control) {
      this.control.control.setValue(filteredInput);
    }
  }
}
