<section>
  <div class="container">
    <div class="boxStyle">
      <h1>
        The page you're looking for hasn't been found.
      </h1>
      <div class="containerContent">
        <p>Go back to the <a href="/home">home page</a>.</p>
      </div>
    </div>
  </div>
</section>
