import { Router } from '@angular/router';
import {
  HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { api_url } from '../utils/constants.helper';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../authentication.service';

@Injectable()
export class ActivationHttpInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('/api/content') || !this.router.routerState.snapshot.url.includes('card-activation')) {
      return next.handle(request);
    }

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      client_id: environment.apigee_sdc_clientid,
    });
    if (
      request.url.indexOf(api_url.VALIDATION) === -1
      && request.url.indexOf(api_url.DECRYPT_USER) === -1
    ) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        client_id: environment.apigee_sdc_clientid,
        Authorization: `Bearer ${this.authService.getTokenValue('default')}`,
      });
    }
    let modifiedRequest = request.clone({ headers });
    if (
      request.url.indexOf(api_url.OTP_REGISTER) !== -1
      || request.url.indexOf(api_url.OTP_VERIFY) !== -1
    ) {
      modifiedRequest = request;
    }
    return next.handle(modifiedRequest);
  }
}
