import {
  Component, Input, NgZone, OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isLoggedIn: boolean = false;

  constructor(private authService: AuthenticationService, private ngZone: NgZone, private router: Router) {
  }

  ngOnInit() {
    this.authService.isSsoAuthenticated$.subscribe((value: boolean) => {
      this.isLoggedIn = value;

      if (!value) {
        this.initializeGoogleSignIn();
      }
    });
  }

  initializeGoogleSignIn() {
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: this.ssoCallback.bind(this),
      auto_select: false,
      cancel_on_tap_outside: true,
    });
    google.accounts.id.renderButton(
      document.getElementById('google-button')!,
      {
        type: 'standard',
        theme: 'filled_blue',
        size: 'large',
        text: 'continue_with',
        shape: 'pill'
      }
    );
  }

  async ssoCallback(response: any): Promise<void> {
    if (response.credential) {
      this.ngZone.run(() => {
        this.authService.login(response.credential, 'sso');
      });
    }
  }

  logout() {
    this.authService.logout('sso');
    this.router.navigate(['home']);
    this.initializeGoogleSignIn();
  }
}
