export const commonLabels = {
  SUBMIT: 'Submit',
  CONTINUE: 'Continue',
  CANCEL: 'Cancel',
  BACK: 'Back',
  RESET: 'Reset',
  ACCEPT: 'Accept',
  EMPLOYEE: 'Employee number',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  DATE_OF_BIRTH: 'Date of birth',
  EMAIL: 'Email',
  MOBILE_NO: 'Mobile number',
  TITLE: 'Title',
  GENDER: 'Gender',
};
export const redirectPageLabels = {
  HEADER: 'You are being redirected to the Team Discount Card Self Serve Portal',
  DESCRIPTION: 'For your Everyday Rewards Plus Card or Team Discount Card'
};
export const primaryCardDetailsLabels = {
  HEADER: 'Primary Card Holder Details',
  NAME: 'Name',
  EMAIL: commonLabels.EMAIL,
  EMPLOYEE_NUMBER: commonLabels.EMPLOYEE,
  CONTACT_NUMBER: 'Contact number',
  BRAND: 'Brand',
  CARD_NO: 'Current Card Number'
};

export const secondaryCardDetailsLabels = {
  HEADER: 'Current Secondary card holders details',
  FIRSTNAME: commonLabels.FIRST_NAME,
  FIRSTNAME_ERROR:
    'Please enter First name. Alphabets, space, dot, quote and hyphens are only allowed',
  LASTNAME: commonLabels.LAST_NAME,
  LASTNAME_ERROR:
    'Please enter Last name. Alphabets, space, dot, quote and hyphens are only allowed',
  DOB: commonLabels.DATE_OF_BIRTH,

  CARD: 'Card Number',
};

export const secondaryCarInputdDetailsLabels = {
  HEADER: 'Nominated secondary card holders details',
  FIRSTNAME: `${commonLabels.FIRST_NAME}`,
  FIRSTNAME_ERROR:
    'Please enter First name. Alphabets, space, dot, quote and hyphens are only allowed',
  LASTNAME: `${commonLabels.LAST_NAME}`,
  LASTNAME_ERROR:
    'Please enter Last name. Alphabets, space, dot, quote and hyphens are only allowed',
  EMAIL: `${commonLabels.EMAIL}`,
  EMAIL_ERROR: 'Please enter a valid email address.',
  DOB: `${commonLabels.DATE_OF_BIRTH}`,
  DOB_ERROR: 'Please select Date of birth',
  CONTACT_NUMBER: `${commonLabels.MOBILE_NO}`,
  CONTACT_NUMBER_ERROR: 'Please enter a valid mobile number.',
  TITLE: `${commonLabels.TITLE}`,
  TITLE_ERROR: 'Please select the Title',
  GENDER: `${commonLabels.GENDER}`,
  GENDER_ERROR: 'Please select the Gender',
  UNIQUE_EMAIL_ERROR: 'This email id is already in use. Please enter another valid email id',
  GENDER_TITLE_MISMATCH: 'Gender and Title Mismatch'
};
export const addressDetailsLabel = {
  HEADER: 'Address Details',
  SECONDARY_ADDRESS_HEADER: 'Secondary Card Holder Address Details',
  UNIT_NO: 'Unit number',
  UNIT_NO_ERROR:
    'Please enter unit number. Alphabets, space, forward slash, numbers and hyphens are only allowed',
  STREET_NO: 'Street number',
  STREET_NO_ERROR:
    'Please enter street number. Alphabets, space, forward slash, numbers and hyphens are only allowed',
  STREET_NAME: 'Street name',
  ADDRESS: 'Address',
  ADDRESS_ERROR: 'Please enter address',
  STREET_NAME_ERROR:
    'Please enter street name. Alphabets, numbers, forward slash, quotes, hyphens, spaces are only allowed',
  SUBURB: 'Suburb',
  SUBURB_ERROR: 'Please enter suburb. Alphabets, spaces are only allowed',
  STATE: 'State',
  STATE_ERROR: 'Please select state',
  POSTCODE: 'Postcode',
  POSTCODE_ERROR: 'Please enter postcode. Numbers are only allowed',
  COUNTRY: 'Country',
  COUNTRY_ERROR: 'PLease select Country',
  UPDATE_SUCCESS: 'Address has been updated successfully',
  COUNTRY_CHANGE_ERROR: 'As you are changing your country location, you cannot proceed with this request. For assistance, please contact teambenefits@woolworths.com.au OR  call 1800 008584 option 5.',
  UPDATE: 'Update Address',
  SAVE: 'Save',
  CANCEL: 'Cancel',
};
export const declarationsLabel = {
  HEADER: 'Declarations ',
  DESCRIPTION: ' Please read and acknowledge',
  DECLARATION_ONE:
    'Does this person work for Woolworths Group Limited or Partners*?',
  DECLARATION_TWO:
    'I can confirm the person nominated as the recipient of my Secondary Everyday Rewards Plus Card/Team Discount Card is an immediate family member who resides within my household. Should this situation change, I understand I am responsible to cancel my Secondary Everyday Rewards Plus Card/Team Discount Card.* ',
  DECLARATION_THREE: 'I have advised the nominated secondary card holder that their personal information will be shared with Woolworths Group and will be managed in accordance with the company\'s Privacy Policy',
  DECLARATION_ONE_ERROR:
    'Invalid nominated secondary card holder. Team members cannot be a secondary card holder.',
  DECLARATION_TWO_ERROR:
    'Invalid nominated secondary card holder. Nominated card holders must belong to the same household as the primary card holder.',
  DECLARATION_THREE_ERROR: 'Prior to a secondary card being issued, you must notify the cardholder that their personal details will be shared with Woolworths and will be managed in accordance with the company\'s Privacy Policy',
  DECLARTION_YES: 'Yes',
  DECLARTION_NO: 'No',
  DECLARATION_ERROR: 'Please select declaration',
};

export const cardReplaceReason = {
  LABEL: 'Reason for requesting a replacement card',
  ERROR: 'Please select reason for requesting a replacement card',
};

export const relationshiptTypes = {
  HEADER: 'Relationship to nominated secondary card holder',
  RELATIONSHIP_HEADER:
    'Relationship',
  RELATIONSHIP_ERROR: 'Please select relationships',
  RELATIONSHIP_GENDER_MISMATCH: 'Gender and relationship mismatch'
};

export const LABELS = {
  landing: {
    LANDING_HEADER:
      'Team Discount Self Serve Portal',
    LANDING_DESCRIPTION:
      'Enter your personal details  as they appear  on your Payslip',
    LANDING_FIRST_NAME: `${commonLabels.FIRST_NAME}`,
    LANDING_LAST_NAME: `${commonLabels.LAST_NAME}`,
    LANDING_DOB: `${commonLabels.DATE_OF_BIRTH}`,
    LANDING_EMPLOYEE: `${commonLabels.EMPLOYEE}`,
    LANDING_ACTION: 'Action',
    LANDING_EMPLOYEE_ERROR:
      'Please enter alphanumerics only and must not exceed 20',
    LANDING_FIRST_NAME_ERROR:
      'Please enter First Name. Alphabets, space, dot, quote and hyphens are only allowed',
    LANDING_LAST_NAME_ERROR:
      'Please enter Last Name. Alphabets, space, dot, quote and hyphens are only allowed',
    LANDING_DOB_ERROR: 'Please enter Date of Birth',
    LANDING_ACTIONS_ERROR: 'Please select actions',
    LANDING_SUBMIT: commonLabels.SUBMIT,
    LANDING_CONDITION_HEADING: 'You can use this Form to request the following in relation to Everyday Rewards Plus Cards or Team Discount Cards (as applicable to your location). Team members no longer require a physical Everyday Rewards Plus Card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card.',
    LANDING_CONDITIONS: [
      '1. Request a secondary card for a member of your household. ',
      '2. Request a replacement primary card for yourself.',
      '3. Request a replacement card for your secondary card holder.',
      "4. Cancel your secondary card holder's card.",
      '5. Activate your cards.',
    ],
    LANDING_INELIGIBLE:
      'If you are not eligible, this form will not allow you to continue. ',
    LANDING_INELIGIBLE_HEADER: 'If you have issues, please contact as follows:',
    LANDING_HELP: [
      'Australia Contact: teambenefits@woolworths.com.au OR  call 1800 008584 option 5 OR Refer to <a target="_blank" href="/team-discount-selfserve/terms-conditions?country=AU">Terms and Conditions</a> ',
      'New Zealand Contact: staffdisc@countdown.co.nz OR Refer to <a target="_blank" href="/team-discount-selfserve/terms-conditions?country=NZ">Terms and Conditions</a>  ',
      'Asia contact: teambenefits@woolworths.com.au OR  call 1800 008584 option 5 OR Refer to <a target="_blank" href="/team-discount-selfserve/terms-conditions?country=OT">Terms and Conditions</a>',
    ],
    LANDING_RESET: commonLabels.RESET
  },
  applySecondaryCard: {
    HEADER: 'Apply for Secondary Everyday Rewards Plus Card',
    SUBMIT: commonLabels.SUBMIT,
    BACK: commonLabels.BACK,
    DIALOG_CONTENT:
      'Would you like to continue?',
    CONFIRMATION_CONTENT: [
      {
        Australia: {
          title: 'Your secondary Everyday Rewards Plus Card application request has been processed.',
          // eslint-disable-next-line
          body: '<p>Your new card number is <strong>${cardNo}</strong>.</p><p>You will need to activate your new Everyday Reward Plus Card before it can be used. Please check your email and follow the link provided to retrieve your Everyday Rewards Plus Card number and activation instructions.</p>',
        }
      },
      {
        'New Zealand': {
          // eslint-disable-next-line
          title: 'You can use your new Everyday Rewards Plus card number <strong>${cardNo}</strong> on your <a target="_blank" href="https://www.everydayrewards.co.nz/app">digital  device</a>',
          // eslint-disable-next-line
          body: '<p> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p> If you require further help or support please contact <span class="link-button">staffdisc@countdown.co.nz</span></p>',
        }
      },
      {
        Others: {
          title: 'Your secondary Team Discount Card application request has been processed.',
          // eslint-disable-next-line
          body: '<p>Your new card number is <strong>${cardNo}</strong></p><p>please upload it to your <a target="_blank" href="https://www.woolworthsrewards.com.au/app.html">mobile device</a></p>',
        }
      },
    ],
  },
  cancelSecondaryCard: {
    HEADER: 'Cancel Secondary Everyday Rewards Plus Card',
    CANCEL_REASON_HEADER: 'Reason for Cancellation',
    CANCEL_REASON_ERROR: 'Please select reason for cancellation',
    CANCEL_OPTION_HINT:
      '* An Everyday Rewards Card will be issued in its place',
    DIALOG_CONTENT:
      'Please note, your secondary card will be cancelled. Please confirm if you would like to proceed.',
    SUBMIT: commonLabels.SUBMIT,
    BACK: commonLabels.BACK,
    CONFIRMATION_CONTENT: [
      {
        Australia_employeeRequest: {
          title: 'Your Everyday Rewards Plus Card cancellation request has been processed and your secondary Everyday Rewards Plus Card has been cancelled.',
          // eslint-disable-next-line
          body: '<p>A new Everyday Rewards Card <strong>${cardNo}</strong> has been issued in its place and will be delivered to the address on file. All points, exclusive boosters and extras linked to your cancelled Everyday Rewards Plus Card have been transferred to your new Everyday Rewards Card.</p><p>A confirmation message has also been sent to your email account for reference. </p>',
        }
      },
      {
        Australia_deceased: {
          title: 'Your Everyday Rewards Plus Card cancellation request has been processed and your card has been cancelled.',
          // eslint-disable-next-line
          body: '<p>A confirmation message has also been sent to your email account for reference. </p>',
        }
      },
      {
        'New Zealand': {
          title: 'Your Secondary Team Discount Card cancellation request has been processed.Your secondary card has been cancelled.',
          // eslint-disable-next-line
          body: '<p> If you require further help or support please contact <span class="link-button">staffdisc@countdown.co.nz</span></p>',
        }
      },
      {
        Others: {
          title: 'Your Secondary Team Discount Card cancellation request has been processed.Your secondary card has been cancelled.',
          // eslint-disable-next-line
          body: '<p>We will send a confirmation message to your email. If your email on file is incorrect or missing, please contact People Advisory on <span class="link-button">teambenefits@woolworths.com.au OR  call 1800 008584 option 5</span></p>',
        }
      },
    ],
  },
  confirmation: {
    NOT_RECEIVED_EMAIL: 'Resend Email',
    RETURN: 'Return to Login',
    EMAIL_SUCCESS: 'Email has been resent',
  },
  replacePrimaryCard: {
    HEADER: 'Replace Your Primary Everyday Rewards Plus Card',
    BACK: commonLabels.BACK,
    SUBMIT: commonLabels.SUBMIT,
    DIALOG_CONTENT:
      'Would you like to continue?',
    CONFIRMATION_CONTENT_DAMAGED: [
      {
        Australia: {
          // eslint-disable-next-line
          title: 'You can continue to use your existing Everyday Rewards Plus Card <strong>${cardNo}</strong> on your <a target="_blank" href="https://www.woolworthsrewards.com.au/digital-wallet-login.html">digital device</a>.',
          // eslint-disable-next-line
          body: '<p> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p>A confirmation message has also been sent to your email account for reference.</p>',
        }
      },
      {
        'New Zealand': {
          // eslint-disable-next-line
          title: 'You can use your new Everyday Rewards Plus card number <strong>${cardNo}</strong> on your <a target="_blank" href="https://www.everydayrewards.co.nz/app">digital  device</a>.',
          // eslint-disable-next-line
          body: '<p> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p> If you require further help or support please contact <span class="link-button">staffdisc@countdown.co.nz</span></p>',
        }
      },
      {
        Others: {
          // eslint-disable-next-line
          title: 'You can continue to use your existing Team Discount Card <strong>${cardNo}</strong> on your <a target="_blank" href="https://www.woolworthsrewards.com.au/digital-wallet-login.html">digital device</a>.',
          // eslint-disable-next-line
          body: '<p> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p> A confirmation message has also been sent to your email account for reference. <p>',
        }
      },
    ],
    CONFIRMATION_CONTENT_OTHERS: [
      {
        Australia: {
          // eslint-disable-next-line
          title: 'Your Everyday Rewards Plus Card Replacement request has been processed. Your new Everyday Rewards Plus Card number is <strong>${cardNo}</strong> and it is now ready for use.',
          // eslint-disable-next-line
          body: '<p>Your old Everyday Rewards Plus Card has been cancelled. If you still have your previous Everyday Rewards Plus Card in your possession, please ensure you dispose of your card in a secure manner. Any points boosters, extras and faves linked to your Everyday Rewards Plus Card have been transferred to your new Everyday Rewards Plus Card. </p><p>Please add your Everyday Rewards Plus Card to your smart device <a target="_blank" href="https://www.woolworthsrewards.com.au/digital-wallet-login.html">here </a> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p>A confirmation message has also been sent to your email account for reference.</p>',
        }
      },
      {
        'New Zealand': {
          // eslint-disable-next-line
          title: 'You can use your new Everyday Rewards Plus card number <strong>${cardNo}</strong> on your <a target="_blank" href="https://www.everydayrewards.co.nz/app">digital  device</a>.',
          // eslint-disable-next-line
          body: '<p> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p>You old Team Discount Card has been cancelled, we recommend you to destroy and dispose of it correctly.</p><p> If you require further help or support please contact <span class="link-button">staffdisc@countdown.co.nz</span></p>',
        }
      },
      {
        Others: {
          // eslint-disable-next-line
          title: 'Your Team Discount Card replacement request has been processed.Your new card number is <strong>${cardNo}</strong> and it is now ready for use. ',
          // eslint-disable-next-line
          body: '<p>You old Team Discount Card has been cancelled, we recommend you to destroy and dispose of it correctly.</p><p>Please add your Team Discount Card to your smart device  <a target="_blank" href="https://www.woolworthsrewards.com.au/digital-wallet-login.html">here </a></p><p> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p>We will send a confirmation message to your email. If your email on file is incorrect or missing please get it corrected by calling People Advisory on <span class="link-button">teambenefits@woolworths.com.au OR call 1800 008584 option 5</span></p>',
        }
      },
    ],
  },
  replaceSecondaryCard: {
    HEADER: 'Replace Your Secondary Everyday Rewards Plus Card',
    BACK: commonLabels.BACK,
    SUBMIT: commonLabels.SUBMIT,
    ADDRESS_UPDATE_HINT:
      'Address details will be updated from primary card holder',
    DIALOG_CONTENT_OTHERS:
      'Please note, your previously linked secondary card will be cancelled. Would you like to continue?',
    DIALOG_CONTENT_OWNER_CHANGE_AU:
      "Please note, your previously linked secondary card will be cancelled and a new Everyday Rewards card issued in it's place. A new secondary card will be issued against the new nominated card holder and linked to your account. Would you like to continue? ",
    DIALOG_CONTENT_OWNER_CHANGE:
      'Please note, your previously linked secondary card will be cancelled. A new secondary card will be issued against the new nominated card holder and linked to your account. Would you like to continue? ',
    DIALOG_CONTENT_NAME_CHANGE:
      'Would you like to continue?',
    DIALOG_CONTENT_DAMAGED:
      'Would you like to continue?',
    CONFIRMATION_CONTENT_DEFAULT: [
      {
        Australia: {
          title: 'Your Everyday Rewards Plus Card Replacement request has been processed.',
          // eslint-disable-next-line
          body: '<p>Your new Everyday Rewards Plus Card number is <strong>${cardNo}</strong>. It is ready for use.</p><p>Your old  Everyday Rewards Plus Card has been cancelled . If you still have your previous Everyday Rewards Plus Card in your possession, please ensure you dispose of your card in a secure manner.  All points, exclusive boosters and extras linked to your cancelled Everyday Rewards Plus Card have been transferred to your new Everyday Rewards Plus Card.</p><p>Please add your Everyday Rewards Plus Card to your smart device <a target="_blank" href="https://www.woolworthsrewards.com.au/digital-wallet-login.html">here </a> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p>A confirmation message has also been sent to your email account for reference.</p>',
        }
      },
      {
        'New Zealand': {
          // eslint-disable-next-line
          title: 'You can use your new Everyday Rewards Plus card number <strong>${cardNo}</strong> on your <a target="_blank" href="https://www.everydayrewards.co.nz/app">digital  device</a>.',
          // eslint-disable-next-line
          body: '<p> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p>You old Team Discount Card has been cancelled, we recommend you to destroy and dispose of it correctly.</p><p> If you require further help or support please contact <span class="link-button">staffdisc@countdown.co.nz</span></p>',
        }
      },
      {
        Others: {
          // eslint-disable-next-line
          title: 'Your Team Discount Card Replacement request has been processed. Your new card number is  <strong>${cardNo}</strong>.',
          // eslint-disable-next-line
          body: '<p>It is ready for use. </p><p>Your old Team Discount Card has been cancelled. If you still have your previous Team Discount Card in your possession, please ensure you dispose of your card in a secure manner. </p><p>Please add your new Team Discount Card to your smart device <a target="_blank" href="https://www.woolworthsrewards.com.au/digital-wallet-login.html">here </a> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p>A confirmation message has also been sent to your email account for reference. </p>',
        }
      },
    ],
    CONFIRMATION_CONTENT_NAME_CHANGE: [
      {
        Australia: {
          title: 'Your request for a name change has been processed.',
          // eslint-disable-next-line
          body: '<p>You can continue to use your existing Everyday Rewards Plus Card number <strong>${cardNo}</strong> on your <a target="_blank" href="https://www.woolworthsrewards.com.au/digital-wallet-login.html">digital device </a>.</p><p>Please re-add your Everyday Rewards Plus Card on your smart device to ensure your updated name is reflected correctly. </p><p> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p>A confirmation message has also been sent to your email account for reference. </p>',
        }
      },
      {
        'New Zealand': {
          // eslint-disable-next-line
          title: 'You can use your new Everyday Rewards Plus card number <strong>${cardNo}</strong> on your <a target="_blank" href="https://www.everydayrewards.co.nz/app">digital  device</a>.',
          // eslint-disable-next-line
          body: '<p> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p> If you require further help or support please contact <span class="link-button">staffdisc@countdown.co.nz</span></p>',
        }
      },
      {
        Others: {
          title: 'Your request for a name change has been processed.',
          // eslint-disable-next-line
          body: '<p>You can continue to use your existing Team Discount Card number <strong>${cardNo}</strong> on your  <a target="_blank" href="https://www.woolworthsrewards.com.au/digital-wallet-login.html">digital device</a>.</p><p>Please re-add your Team Discount Card on your smart device to ensure your updated name is reflected correctly.  </p><p> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p>A confirmation message has also been sent to your email account for reference.</p>',
        }
      },
    ],
    CONFIRMATION_CONTENT_OWNER_CHANGE: [
      {
        Australia: {
          title: 'Your secondary Everyday Rewards Plus Card replacement request has been processed.',
          // eslint-disable-next-line
          body: '<p>A new Everyday Rewards Plus Card has been issued for your new secondary card holder.This new Everyday Rewards Plus Card number is <strong>${cardNo}</strong>. This Everyday Rewards Plus Card will need to be activated before it can be used.  Please check your email and follow the link provided to retrieve your Everyday Rewards Plus Card number and activation instructions.</p><p>Your old Everyday Rewards Plus Card has been cancelled and replaced with a new Everyday Rewards Card ${newEDRCard}. Any points, exclusive boosters and extras linked to your cancelled Everyday Rewards Plus Card have been transferred to the new Everyday Rewards Card .Your new Everyday Rewards Card will be delivered to the mailing address on file.</p><p>A confirmation message has also been sent to your email account for reference.</p>',
        }
      },
      {
        'New Zealand': {
          // eslint-disable-next-line
          title: 'You can use your new Everyday Rewards Plus card number <strong>${cardNo}</strong> on your <a target="_blank" href="https://www.everydayrewards.co.nz/app">digital  device</a>.',
          // eslint-disable-next-line
          body: '<p> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p>You old secondary Team Discount Card has been cancelled, we recommend you to destroy and dispose of it correctly. </p><p> If you require further help or support please contact <span class="link-button">staffdisc@countdown.co.nz</span></p>',
        }
      },
      {
        Others: {
          title: 'Your new secondary Team  Discount Card application request has been processed.',
          // eslint-disable-next-line
          body: '<p>You old secondary Team Discount Card has been cancelled, we recommend you to destroy and dispose of it correctly. </p><p>Your new card number is <strong>${cardNo}</strong>, please upload it to your  <a target="_blank" href="https://www.woolworthsrewards.com.au/digital-wallet-login.html">mobile device </a>. </p><p> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p>A confirmation email has been sent to you email account</p>',
        }
      },
    ],
    CONFIRMATION_CONTENT_DAMAGED: [
      {
        Australia: {
          // eslint-disable-next-line
          title: 'You can continue to use your existing Everyday Rewards Plus Card number <strong>${cardNo}</strong> on your  <a target="_blank" href="https://www.woolworthsrewards.com.au/digital-wallet-login.html">digital device</a>.',
          // eslint-disable-next-line
          body: '<p> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p>A confirmation message is also sent to your email account for reference.</p>',
        }
      },
      {
        'New Zealand': {
          // eslint-disable-next-line
          title: 'You can use your new Everyday Rewards Plus card number <strong>${cardNo}</strong> on your <a target="_blank" href="https://www.everydayrewards.co.nz/app">digital  device</a>',
          // eslint-disable-next-line
          body: "<p> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p> If you require further help or support please contact <span class='link-button'>staffdisc@countdown.co.nz</span></p>",
        }
      },
      {
        Others: {
          // eslint-disable-next-line
          title: 'You can continue to use your existing Team Discount Card number <strong>${cardNo}</strong> on your  <a target="_blank" href="https://www.woolworthsrewards.com.au/digital-wallet-login.html">digital device</a>.',
          // eslint-disable-next-line
          body: '<p> Team members no longer require a physical Everyday Rewards Plus card to redeem our great offers, and as such, we no longer send physical cards. You can enjoy all the benefits of being a Everyday Rewards Plus member with your digital card. </p><p>A confirmation message is also sent to your email account for reference.</p>',
        }
      },
    ],
  },
};
