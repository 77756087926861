import { LABELS } from './labels.helper';

export const tooltip_delay = {
  SHOW_DELAY: 100,
  HIDE_DELAY: 2000,
};

export const STATES = ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'ACT', 'NT '];

export const regex = {
  CARD_NUMBER_REGEX: '^[0-9]{13}$',
  EMPLOYEE_NUMBER_REGEX: '^[a-z0-9A-Z]{1,20}$',
  NAME_REGEX: "(^[A-Za-z\\s.'-]+$)",
  NUMBER_REGEX: '^[0-9]+$',
  NUMBER_ALPHABETS_HYPHENS_REGEX: '^[0-9-a-zA-Z/ ]+$',
  ALPA_SPACE_HYPHENS_REGEX: "^[A-Za-z-' ]+$",
  ALPABETS_REGEX: '^[A-Za-z ]+$',
  PASSWORD_REGEX: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$',
  // eslint-disable-next-line
  MOBILE_NUMBER_REGEX: '(^0[4]{1}[0-9]{8}$)|(^04\d{2,3}\d{6}$)|(^0[2]{1}[0-9]{8}$)|(^0[3]{1}[0-9]{8}$)|(^0[8]{1}[0-9]{8}$)|(^0[7]{1}[0-9]{8}$)',
  EMAIL_REGEX:
  // eslint-disable-next-line
    '(^[_A-Za-z0-9-]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,4})$)',
};

export const api_url = {
  VALIDATION: '/wx/v1/loyalty/sdc/self-serve/employees/validate',
  EMPLOYEE_DETAILS: '/wx/v1/loyalty/sdc/self-serve/employees/',
  ACTIVATION: '/wx/v1/loyalty/sdc/self-serve/card/activation',
  NZ_ACTIVATION: '/wx/v1/loyalty/sdc/self-serve/card/nz/activation',
  OTP_REGISTER: '/wx/v1/loyalty/sdc/self-serve/employees/one-time-passcode',
  OTP_VERIFY: '/wx/api-otp/verifytoken/sdcregister',
  DECRYPT_USER: '/wx/v1/loyalty/sdc/self-serve/employees/decrypt/',
  UPDATE_PASSWORD: '/wx/v1/loyalty/sdc/self-serve/card/identity-account',
};

export const GENERIC_ERROR = 'Something went wrong! Please try again later.';
export const TOKEN_EXPIRY = 'The access token is expired';
export const TOKEN_EXPIRY_MESSAGE = 'Your session expired.';
export const TOKEN_EXPIRY_REENTER_MESSAGE = 'Please re-enter details to continue.';
export const TOKEN_EXPIRY_SET_PASSWORD_FAILED = 'A Password has not been created for your account, please contact Customer Hub on 1300101234 to get it reset.';
export const activation_labels = LABELS;

export const FAQList = [
  { code: 'all', name: 'Select a category' },
  { code: '1', name: 'EVERYDAY REWARDS PLUS CARD AND EVERYDAY REWARDS' },
  { code: '2', name: 'ACTIVATION AND REGISTRATION PROCESS' },
  { code: '3', name: 'SECONDARY CARD' },
  { code: '4', name: 'REPLACEMENT CARD' },
  { code: '5', name: 'WOOLWORTHS FOODCO PRODUCTS' },
  { code: '6', name: 'PINNACLE DRINK PRODUCTS' },
  { code: '7', name: 'FUEL OFFERS' },
  { code: '8', name: 'MOBILE AND ONLINE ACCOUNTS' },
  { code: '9', name: 'PRIVACY AND OPT-OUT PREFERENCES' },
];
