import {
  HttpContext, HttpHeaders, HttpParams, HttpRequest
} from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  constructor() { }

  onLoadingChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Stores all currently active requests.
   */
  private requests: HttpRequest<any>[] = [];

  /**
   * Adds request and notifies observers.
   *
   * @param {HttpRequest} req -for http request.
   */
  onStarted(req: HttpRequest<any>): void {
    this.requests.push(req);
    this.notify();
  }

  /**
   * Removes request from the storage and notifies observers.
   *
   * @param {HttpRequest} req -for http request.
   */
  onFinished(req: HttpRequest<any>): void {
    const index = this.requests.indexOf(req);
    if (index !== -1) {
      this.requests.splice(index, 1);
    }
    this.notify();
  }

  /**
   * Notifies observers about whether there are any requests on fly.
   */
  private notify(): void {
    this.onLoadingChanged.emit(this.requests.length !== 0);
  }
}
