import { GENERIC_ERROR } from '../constants.helper';

/**
 * Handles errors and formats them into a standardized error message object.
 *
 * @param {any} error - The error object that needs to be processed.
 * @returns {{code: string, message: string}} - An object containing a standardized error code and message.
 *
 * This function processes an input error object to extract and format a standardized error message. It attempts to find a specific error message and code within the error object. If specific error details are found, they are used; otherwise, a generic error message is returned. The function handles different structures of the error object, including nested error messages. This is useful for standardizing error handling across different parts of an application, especially for user-facing error messages.
 */
export function handleError(error: any) {
  let errorMessage = { code: 'ERROR', message: GENERIC_ERROR };
  try {
    if (error.error.errors) {
      errorMessage = error.error.errors[0].message
        ? {
          code: error.error.errors[0].code,
          message: error.error.errors[0].message,
        }
        : { code: 'ERROR', message: GENERIC_ERROR };
    } else if (error.error) {
      errorMessage = error.error.message
        ? { code: error.error.code, message: error.error.message }
        : { code: 'ERROR', message: GENERIC_ERROR };
    }
  } catch (e) {
    return errorMessage;
  }
  return errorMessage;
}
