import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ITbValuedBenefitsHero } from 'src/app/shared/interfaces/contentful';

@Component({
  standalone: true,
  selector: 'app-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.scss'],
  imports: [CommonModule],

})
export class HeroBannerComponent {
  @Input() twoColum:boolean = false;

  @Input() hero!: ITbValuedBenefitsHero;
  
  constructor() {}

  checkImageType(string: any) {
    return string ? string.includes('image') : true;
  }
}
