<form class="search-wrapper" (ngSubmit)="onSearchClick()">
  <input
    type="text"
    placeholder="Search"
    [(ngModel)]="searchTerm"
    name="search"
    class="search-input"
    appSearchAllowedChars
  />
  <button type="submit" class="search-button" mat-icon-button>
    <span class="material-icons">search</span>
  </button>
</form>
