import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private loggedInUserDetailsSubject = new BehaviorSubject<any>(null);

  loggedInUserDetails = this.loggedInUserDetailsSubject.asObservable();

  private accessTokenExpirySubject = new BehaviorSubject<boolean>(false);

  accessTokenExpiry = this.accessTokenExpirySubject.asObservable();

  constructor() {}

  updateLoggedInUserDetails(details: any): void {
    this.loggedInUserDetailsSubject.next(details);
  }

  updateAccessTokenExpiry(token: any) {
    this.accessTokenExpirySubject.next(token);
  }
}
