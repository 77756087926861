<app-banner
  [imageUrl]="fields?.heroImage?.fields?.file?.url ?? ''"
  [heading]="fields.heroTitleLine1 + ' ' + fields.heroTitleLine2"
>
  <span [innerHTML]="fields.heroLeadParagraph | richText"></span>
</app-banner>

<h1 class="primaryColor cardSectionTitle">{{fields.cardsSectionTItle}}</h1>
<div class="container">
  <app-card
    *ngFor="let card of cards"
    [imageUrl]="card.fields.tileImage?.fields.file.url"
    [heading]="card.fields.title"
    [description]="card.fields.content | richText"
    [callToActionsTable]="card.fields.callToActions"
    [footerImageUrl]="card.fields.footerImage?.fields?.file?.url"
  >
  </app-card>
</div>
