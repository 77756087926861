import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { ContentService } from 'src/app/content.service';
import { EDR_CONTENT_ID } from 'src/app/shared/constants';
import {
  ITbCard,
  ITbValuedBenefitsHero,
} from '../../shared/interfaces/contentful';
import { AuthenticationService } from '../../authentication.service';

@Component({
  selector: 'app-iah-home',
  templateUrl: './iah-home.component.html',
  styleUrls: ['./iah-home.component.scss'],
})
export class IahHomeComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean = false;

  private currentContentId: string = '';

  private contentSubscription: Subscription | undefined;

  private authSubscription!: Subscription;

  hero!: ITbValuedBenefitsHero;

  cards: ITbCard[] = [];

  constructor(
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private contentService: ContentService
  ) { }

  ngOnInit() {
    this.authSubscription = this.authService.isSsoAuthenticated$.subscribe(
      (isAuth: boolean) => {
        this.isAuthenticated = isAuth;
      },
    );

    this.activatedRoute.data.subscribe((data: any) => {
      const { fields } = data?.content || {};
      const { title } = fields;
      this.currentContentId = data?.contentId || '';
      this.updateUIElements(fields, title);
    });

    this.contentService.previewsVisible.subscribe((enabled) => {
      if (enabled) {
        this.contentSubscription = this.contentService.getContentById(this.currentContentId, 2).subscribe((data: any) => {
          const { fields } = data || {};
          const { title } = data?.fields || '';
          this.updateUIElements(fields, title);
        });
      } else {
        this.contentSubscription?.unsubscribe();
      }
    });
  }

  private updateUIElements(fields: any, title: any) {
    this.hero = fields.hero;
    this.cards = fields.cards;
    this.titleService.setTitle(`Team Benefits | ${title}`);
  }

  ngOnDestroy() {
    // Always unsubscribe to prevent memory leaks
    this.authSubscription.unsubscribe();
    this.contentSubscription?.unsubscribe();
  }
}
