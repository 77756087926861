import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentService } from '../../content.service';
import { IMaintenance } from '../interfaces/contentful';
import { MAINTENANCE_CONTENT_ID } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard implements CanActivate {
  constructor(private router: Router, private contentService: ContentService) {}

  canActivate(): Observable<boolean> {
    return this.contentService.getContentById(MAINTENANCE_CONTENT_ID).pipe(
      map((response: IMaintenance) => {
        if (!response.fields.selveServeInMaintenanceMode) {
          return true;
        }

        const maintenanceBypass = localStorage.getItem('teamBenefitsMaintenanceBypass');
        if (maintenanceBypass) {
          return true;
        }
        this.router.navigate(['/maintenance']);
        return false;
      })
    );
  }
}
