import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  searchTerm: string = '';

  constructor(private router: Router) {}

  onSearchClick(): void {
    if (this.searchTerm.length === 0 || !this.searchTerm.trim().match(/^[a-zA-Z0-9 ]{4,}\b$/)) {
      return;
    }

    this.router.navigate(['/search'], { queryParams: { term: this.searchTerm } });
  }
}
