import {
  animate, style, transition, trigger
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/shared/service/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [CommonModule],

  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('80ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('80ms', style({ opacity: 0 })),
      ])
    ])
  ]
})
export class LoaderComponent implements OnInit {
  isLoading: boolean = false;

  constructor(private _loaderService: LoaderService) {
  }

  ngOnInit(): void {
    this._loaderService.onLoadingChanged.subscribe(
      (isLoading) => (this.isLoading = isLoading)
    );
  }
}
