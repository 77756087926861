import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

type AuthType = 'default' | 'sso'

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly tokenNames = {
    default: 'bearer_token',
    sso: 'team-benefits-sso-token',
  };

  private _isDefaultAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _isSsoAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isDefaultAuthenticated$: Observable<boolean> = this._isDefaultAuthenticatedSubject.asObservable();

  public isSsoAuthenticated$: Observable<boolean> = this._isSsoAuthenticatedSubject.asObservable();

  constructor() {
    this._isDefaultAuthenticatedSubject.next(this.hasToken('default'));
    this._isSsoAuthenticatedSubject.next(this.hasToken('sso'));
  }

  private hasToken(type: AuthType): boolean {
    return !!localStorage.getItem(this.tokenNames[type]);
  }

  public login(token: string, type: AuthType): void {
    localStorage.setItem(this.tokenNames[type], token);
    if (type === 'default') {
      this._isDefaultAuthenticatedSubject.next(true);
    } else {
      this._isSsoAuthenticatedSubject.next(true);
    }
  }

  public logout(type: AuthType): void {
    localStorage.removeItem(this.tokenNames[type]);
    if (type === 'default') {
      this._isDefaultAuthenticatedSubject.next(false);
    } else {
      this._isSsoAuthenticatedSubject.next(false);
    }
  }

  public getTokenValue(type: AuthType): string | null {
    return localStorage.getItem(this.tokenNames[type]);
  }
}
