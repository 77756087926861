import { NgModule, inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterModule,
  Routes,
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { IahHomeComponent } from './iah-home/iah-home.component';
import { BannerComponent } from '../components/banner/banner.component';
import { CardComponent } from '../components/card/card.component';
import { ContentService } from '../content.service';
import { RichTextPipe } from '../rich-text.pipe';
import { HeroBannerComponent } from '../components/hero-banner/hero-banner.component';
import { IAM_CONTENT_ID } from '../shared/constants';
import { IamHomeChildComponent } from './iam-home-child/iam-home-child.component';

export const contentByIdResolver: ResolveFn<any> = () => {
  const contentId = IAM_CONTENT_ID;
  const contentService = inject(ContentService);
  const titleService = inject(Title);

  return contentService.getContentById(contentId).pipe(
    map((content) => {
      const title = content?.fields?.title || '';
      titleService.setTitle(`Team Benefits | ${title}`);
      return content;
    })
  );
};

export const contentBySlugResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  const contentService = inject(ContentService);
  const titleService = inject(Title);

  const slug = route.paramMap.get('slug');
  return contentService.getContentBySlug('tbCategoryPage', slug || '').pipe(
    map((content) => {
      const title = content?.fields?.title || 'Default Title';
      titleService.setTitle(`Team Benefits | ${title}`);
      return content;
    })
  );
};

export const contentBySlugsResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  const contentService = inject(ContentService);
  const titleService = inject(Title);

  return contentService.getContentBySlug(
    'tbCategoryPage',
    `${route.paramMap.get('parentSlug')}/${route.paramMap.get('slug')}` || ''
  ).pipe(
    map((content) => {
      const title = content?.fields?.title || '';
      titleService.setTitle(`Team Benefits | ${title}`);
      return content;
    })
  );
};

const iAmHereRoutes: Routes = [
  {
    path: '',
    component: IahHomeComponent, // RootComponent will be the default route
    resolve: { content: contentByIdResolver, },
    data: { contentId: IAM_CONTENT_ID }
  },
  {
    path: ':slug',
    component: IamHomeChildComponent,
    resolve: { content: contentBySlugResolver },
  },
  {
    path: ':parentSlug/:slug',
    component: IamHomeChildComponent,
    resolve: { content: contentBySlugsResolver },
  },
];

@NgModule({
  declarations: [IahHomeComponent, IamHomeChildComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(iAmHereRoutes),
    BannerComponent,
    CardComponent,
    RichTextPipe,
    HeroBannerComponent,
  ],
})
export class IAmHereModule { }
